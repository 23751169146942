// src/services/authService.js

export const mockLoginApi = (email, password) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (email === "test@example.com" && password === "password") {
          resolve({
            data: {
              id: 1010, // Return the hardcoded ID
            },
          });
        } else {
          reject(new Error("Invalid email or password"));
        }
      }, 1000);
    });
  };
  