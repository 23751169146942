import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './FrameComponent.css';
import { getProfile } from '../services/apiService'; // Import API services

const FrameComponent = ({ className = '', isLoggedIn, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleLogout = () => {
    setIsLoggedIn(false);
    navigate('/login');
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleProfileNavigation = () => {
    console.log('Fetching profile data...');
    getProfile() // Fetch profile to determine if it exists
      .then(response => {
        console.log('Profile data fetched:', response.data);
        const profileExists = response.data && Object.values(response.data.demographics).some(field => field !== null);
        console.log('Profile exists:', profileExists);

        if (profileExists) {
          console.log('Navigating to profile page...');
          navigate('/profile', { state: { subscriber_id: response.data.subscriber_id } }); // Navigate to profile (readonly)
        } else {
          console.log('Navigating to profile-update page...');
          navigate('/profile-update'); // Navigate to profile update (editable)
        }
      })
      .catch(error => {
        console.error('Error fetching profile:', error);
        navigate('/profile-update'); // Navigate to profile update if there's an error or no profile
      });
  };

  return (
    <div className={`rectangle-group ${className}`}>
      <div className="rectangle-div" />
      <img
        className="final-logo-csdlc-1"
        loading="lazy"
        alt=""
        src="/subscription/final-logo-csdlc-1.svg"
      />
      <div className="user-menu" onClick={toggleDropdown} ref={dropdownRef}>
        <div className="user-icon-wrapper">
          <img className="user-icon" loading="lazy" alt="" src="/subscription/user-icon.svg" />
          <img className="vector-icon3" alt="" src="/subscription/vector-3.svg" />
        </div>
        {dropdownVisible && (
          <div className="dropdown-content">
            {!isLoggedIn ? (
              <div className="dropdown-item" onClick={() => navigate('/login')}>
                <div className="dropdown-text">Login</div>
              </div>
            ) : (
              <>
                <div className="dropdown-item" onClick={handleProfileNavigation}>
                  <img src="/layout/settings-icon.png" alt="Settings" className="dropdown-icon" />
                  <div className="dropdown-text">My Profile</div>
                </div>
                <div className="dropdown-item" onClick={handleLogout}>
                  <img src="/layout/logout-icon.png" alt="Logout" className="dropdown-icon" />
                  <div className="dropdown-text">Logout</div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  setIsLoggedIn: PropTypes.func.isRequired,
};

export default FrameComponent;
