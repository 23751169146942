import React, { useState } from 'react';
import "./faq.css"

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    { question: "What benefits do I get for subscribing to csdlc.org?", answer: "This portal empowers you with access to the latest healthcare research, including rigorous clinical trials that are essential in advancing medicine. By subscribing, you gain control over your health journey, stay informed on the newest trends in healthcare, and receive personalized trial recommendations, enabling you to make informed decisions and potentially participate in trials at your own discretion." },
    { question: "How often will I receive the trend reports and knowledge graphs?", answer: "Subscribers will receive trend reports and knowledge graphs twice a month, keeping you regularly updated on the latest developments in healthcare research." },
    { question: "How can I access the personalized trial recommendations after subscribing?", answer: "After subscribing, personalized trial recommendations will be made available directly through your account on the platform, where you can review and act on them as you see fit." },
    { question: "Is there a trial period before committing to the annual subscription?", answer: "Currently, there is no trial period offered. However, the subscription is priced affordably to ensure you receive maximum value throughout the year." },
    { question: "What payment methods are available for the subscription?", answer: "Payments can be made using simple methods such as QR code or direct bank transfer via IFSC code and account number. After payment, you will need to submit a screenshot for account creation." },
    { question: "Can I cancel my subscription at any time?", answer: "The current subscription model is for a full year, so cancellations might not be allowed mid-term. However, you will retain access to all benefits for the duration of your subscription." },
    { question: "How secure is my data on this platform?", answer: "Your data is protected with robust security measures to ensure privacy and confidentiality. The platform is designed to safeguard your personal and medical information." },
    { question: "What kinds of clinical trials will I be eligible for?", answer: "The platform provides personalized recommendations based on your profile and preferences, allowing you to explore and choose trials that align with your health interests or needs." },
    { question: "How do I know if a trial is suitable for me?", answer: "Each trial recommendation comes with detailed information, including eligibility criteria and potential benefits or risks. This helps you make an informed decision about participation." },
    { question: "Can I share my subscription with family members or friends?", answer: "Subscriptions are currently intended for individual use. However, you can discuss trial information with your family or friends if you believe they might benefit from participating." },
    { question: "How will my data be used by csdlc.org?", answer: "Your data will be used solely to provide you with personalized trial recommendations, trend reports, and knowledge graphs. This ensures that the content you receive is relevant to your health interests and needs." },
    { question: "Will my data be shared with anyone else?", answer: "Your data will never be shared with any third parties, including clinical trial teams, unless you explicitly request and authorize sharing it for participation in a specific clinical trial. We prioritize your privacy and maintain strict control over your data." },
    { question: "What steps does csdlc.org take to protect my privacy?", answer: "We implement strong encryption and data protection measures to safeguard your information. Your privacy is our top priority, and we ensure that your data is secure and only accessible by you." },
  ];

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <div className='hz-line'></div>
      <div className='faq-internal-div'>
        <h2 className="faq-header">Frequently Asked Questions!</h2>
        <p className="faq-subheader">
          Find answers to common queries about CSDLC.org and how our subscription can benefit you.
        </p>
        <div className='faqs-container'>
          {faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <div className={`faq-question-container ${activeIndex === index ? 'active' : ''}`}
                  onClick={() => toggleFaq(index)}>
                <div className="faq-icon">
                  {activeIndex === index ? '-' : '+'}
                </div>
                <div className="faq-question">
                  {item.question}
                </div>
              </div>
              {activeIndex === index && (
                <div className="faq-answer-container">
                  <div className="faq-answer">
                    {item.answer}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>       
      </div>
    </div>     
  );
};

export default FaqSection;
