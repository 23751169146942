import React from 'react';
import './ThankYouPage.css';

const ThankYouPage = () => {
  return (
    <div className="thank-you-page">
      <div className="thank-you-page-child" />
      <div className="thank-you-page-item" />
      <section className="thankyou-content">
        <div className="subscription">
          <div className="message">
            <div className="thank-you-for-container">
              <p className="thank-you-for-subscribing">
                <span>
                  <span className="thank-you-for">Thank You for Subscribing!</span>
                </span>
              </p>
              <p className="blank-line">
                <span>
                  <span>&nbsp;</span>
                </span>
              </p>
              <p className="thank-you-for-subscribing-to-c">
                <span>
                  <span>
                    Thank you for subscribing to CSDLC! We appreciate your support
                    and are excited to have you on board.
                  </span>
                </span>
              </p>
            </div>
          </div>
          <div className="social">
            <b className="follow-us-on">Follow Us on Social Media</b>
            <p className="blank-line33">
              <span>
                <span>&nbsp;</span>
              </span>
            </p>
            <div className="updates">
              <div className="stay-updated-with">
                Stay updated with the latest news, insights, and updates by
                following us on our social media channels:
              </div>
              <div className="social-icons">
                <div className="social-icon">
                  <a href="https://www.linkedin.com/company/vaidhyamegha/" target="_blank" rel="noopener noreferrer">
                    <img
                      className="linkedin-icon ty-icon"
                      loading="lazy"
                      alt="LinkedIn"
                      src="/thankyou/linkedin-icon.svg"
                    />
                  </a>
                </div>
                <div className="line-divider" />
                <div className="social-icon">
                  <a href="https://www.youtube.com/@VaidhyaMeghaProducts" target="_blank" rel="noopener noreferrer">
                    <img
                      className="youtube-icon ty-icon"
                      loading="lazy"
                      alt="YouTube"
                      src="/thankyou/youtube-icon.svg"
                    />
                  </a>
                </div>
                <div className="line-divider" />
                <div className="social-icon">
                  <a href="https://www.instagram.com/vaidhyamegha/" target="_blank" rel="noopener noreferrer">
                    <img
                      className="instagram-icon ty-icon"
                      loading="lazy"
                      alt="Instagram"
                      src="/thankyou/instagram-icon.svg"
                    />
                  </a>
                </div>
                <div className="line-divider" />
                <div className="social-icon">
                  <a href="https://www.facebook.com/vaidhyamegha/" target="_blank" rel="noopener noreferrer">
                    <img
                      className="facebook-icon ty-icon"
                      loading="lazy"
                      alt="Facebook"
                      src="/thankyou/facebook-icon.svg"
                    />
                  </a>
                </div>
                <div className="line-divider" />
                <div className="social-icon">
                  <a href="https://twitter.com/SVaidhyamegha" target="_blank" rel="noopener noreferrer">
                    <img
                      className="x-icon ty-icon"
                      loading="lazy"
                      alt="Twitter"
                      src="/thankyou/x-icon.svg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="spread">
            <div className="share">
              <b className="share-with-your">
                Share with Your Friends and Colleagues
              </b>
              <p className="blank-line33">
                <span>
                  <span>&nbsp;</span>
                </span>
              </p>
              <div className="help-us-grow">
                Help us grow the CSDLC community! Spread the word and refer us to
                your friends and colleagues who might benefit from our services.
              </div>
            </div>
            <p className="blank-line">
              <span>
                <span>&nbsp;</span>
              </span>
            </p>
            <button className="thankyou-search">
              <b className="refer-us">Refer us</b>
            </button>
          </div>
          <div className="next-steps">
            <b className="what-happens-next">What Happens Next?</b>
            <p className="blank-line33">
              <span>
                <span>&nbsp;</span>
              </span>
            </p>
            <div className="steps">
              <div className="verification">
                <div className="payment-verification-we-container">
                  <div className="success">
                    <img
                      className="check-thick-check-form-valida-icon"
                      loading="lazy"
                      alt=""
                      src="/thankyou/checkthickcheckformvalidationcheckmarksuccessaddaddition1tick.svg"
                    />
                    <p className="payment-verification-we-will">
                      <b className="payment-verification">{`Payment Verification: `}</b>
                      <span>
                        We will verify your payment and activate your subscription.
                      </span>
                    </p>
                  </div>
                  <div className="success">
                    <img
                      className="check-thick-check-form-valida-icon1"
                      alt=""
                      src="/thankyou/checkthickcheckformvalidationcheckmarksuccessaddaddition1tick-1.svg"
                    />
                    <p className="welcome-email-you-will-receiv">
                      <b className="welcome-email">{`Welcome Email: `}</b>
                      <span>
                        You will receive a welcome email with your subscription
                        details.
                      </span>
                    </p>
                  </div>
                  <div className="success">
                    <img
                      className="check-thick-check-form-valida-icon2"
                      alt=""
                      src="/thankyou/checkthickcheckformvalidationcheckmarksuccessaddaddition1tick-1.svg"
                    />
                    <p className="access-benefits-start-enjoyin">
                      <b className="access-benefits">{`Access Benefits: `}</b>
                      <span>
                        Start enjoying your subscription benefits, including the
                        trends and knowledge graphs report and personalized list of
                        trials.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="assistance">
            <b className="need-assistance">Need Assistance?</b>
            <p className="blank-line33">
                <span>
                  <span>&nbsp;</span>
                </span>
              </p>
            <div className="if-you-have-container">
              <p className="if-you-have-any-questions-or-n">
                <span>{`If you have any questions or need further assistance, please reach out to us at `}</span>
                <a href="mailto:contact@vaidhyamegha.com" className="ty-vaidhyameghacom">
                  contact@vaidhyamegha.com
                </a>
                <span className="or-91-9618986039"> or </span>
                <a href="tel:+919618986039" className="or-91-9618986039">
                  +91 9618986039
                </a>
              </p>
              <p className="blank-line1">&nbsp;</p>
              <p className="thank-you-for1">
                Thank you for being a part of the CSDLC community!
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ThankYouPage;
