import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { mockLoginApi } from "../../services/authService"; // Import the mock login function
import "./login.css";

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = useCallback(() => {
    mockLoginApi(email, password)
      .then(response => {
        const subscriber_id = response.data.id; // Map id to subscriber_id
        setIsLoggedIn(true);
        // You can now pass the subscriber_id to other components or store it for future use
        console.log("Logged in with Subscriber ID:", subscriber_id);
        navigate("/profile-update", { state: { subscriber_id } }); // Navigate to the profile update page
      })
      .catch(err => {
        setError(err.message);
      });
  }, [email, password, setIsLoggedIn, navigate]);

  return (
    <div className="login">
      <div className="login-content">
        <div className="welcome-back-please-container">
          <p className="welcome-back">
            <span>
              <span className="welcome-back1">Welcome back!</span>
            </span>
          </p>
          <p className="blank-line">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="please-log-in-to-access-your-s">
            <span>
              <span>
                Please log in to access your subscription benefits, including
                trends and knowledge graphs reports and your personalized list of
                trials.
              </span>
            </span>
          </p>
        </div>
        <div className="form">
          <div className="email">
            <div className="email-id-l">Email ID :</div>
            <input
              type="text"
              className="email-child"
              placeholder="Enter your Email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="password">
            <div className="password1">Password :</div>
            <input
              type="password"
              className="password-child"
              placeholder="Enter your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>         
          <div className="search-div">
            <div className="search" onClick={handleLogin}>
              <b className="login1">Login</b>
            </div>
            <div className="error-div">
              {error && <div className="error">{error}</div>}
            </div>
            <div className="change-password">Change Password!</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
