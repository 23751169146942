import React from 'react';
import FrameComponent from './FrameComponent';
import Footer from './Footer';

const Layout = ({ children, isLoggedIn, setIsLoggedIn }) => {
  return (
    <div>
      <FrameComponent isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
