import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import SubscriptionLandingpage from './pages/subscription/SubscriptionLandingpage';
import ThankYouPage from './pages/thankyou/ThankYouPage';
import Login from './pages/login/login';
import ProfileUpdate from './pages/profile-update/profile-update'; // Importing ProfileUpdate component
import Layout from './components/layout';
import { analytics } from './firebase';
import { logEvent } from "firebase/analytics";
import ScrollToTop from './components/scrollToTop'; 

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);  // State to manage login status
  const location = useLocation();  // Hook to access current location/path

  useEffect(() => {
    // Log page view analytics
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
    });
  }, [location]);

  return (
    <Layout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<SubscriptionLandingpage />} />    {/* Home page route for subscription */}
        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} /> {/* // Route for login page */}
        <Route path="/thankyou" element={<ThankYouPage />} />  {/* // Route for thank you page */}
        <Route
          path="/profile-update"
          element={isLoggedIn ? <ProfileUpdate isReadOnly={false} /> : <Navigate to="/login" />}
        />
        <Route
          path="/profile"
          element={isLoggedIn ? <ProfileUpdate isReadOnly={true} /> : <Navigate to="/login" />}
        />
      </Routes>
    </Layout>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
