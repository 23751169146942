// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyAbvpYjPR_zHcy693XqUcdQe11ll2frcLA",
    authDomain: "vaidhyamegha-websites.firebaseapp.com",
    databaseURL: "https://vaidhyamegha-websites.firebaseio.com",
    projectId: "vaidhyamegha-websites",
    storageBucket: "vaidhyamegha-websites.appspot.com",
    messagingSenderId: "378782912530",
    appId: "1:378782912530:web:5ac6d9903eb34db30e48cb",
    measurementId: "G-V6Z164E594"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize services
const firestore = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export { firestore, storage, analytics };
