// resetFormData.js
const resetFormData = () => ({
    fullName: '',
    age: '',
    gender: '',
    country: '',
    address: '',
    pincode: '',
    aadhaar: '',
    abha: '',
    email: '',
    phone: '',
    medicalConditions: '',
    medications: '',
    allergies: '',
    surgeries: '',
    familyHistory: '',
    dietaryHabits: '',
    exerciseFrequency: '',
    smokingStatus: '',
    alcoholConsumption: '',
    substanceUse: '',
    recentLabResults: '',
    diagnosticReports: '',
    height: '',
    weight: '',
    bmi: '',
    participationAvailability: '',
    agree: false
  });
  
  export default resetFormData;
  