import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Ensure FormData is correctly sent as multipart/form-data
export const createProfile = (data) => {
  return axios.post(`${API_BASE_URL}/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    transformRequest: [(data) => data],  // Prevent Axios from transforming FormData
  });
};

export const updateProfile = (subscriberId, data) => {
  return axios.put(`${API_BASE_URL}/${subscriberId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    transformRequest: [(data) => data],  // Prevent Axios from transforming FormData
  });
};

export const getProfile = (subscriberId) => {
  return axios.get(`${API_BASE_URL}/${subscriberId}`);
};
