import React, { useRef, useState, useEffect } from 'react';
import './ProfileUpdate.css';
import { medicalConditions, dietaryHabits, exerciseFrequency, smokingStatus, alcoholConsumption, participationAvailability } from './dropdownData'; // Import dropdown data
import { filterProfileData } from './filterProfileData'; // Import profile utilities
import { createProfile, updateProfile, getProfile } from '../../services/apiService'; // Import API services
import { useNavigate, useLocation } from 'react-router-dom'; // Import hooks
import { countries } from 'country-data';
import resetFormData from './resetFormData';


const Dropdown = ({ label, name, options, required, value, onChange, isEditable }) => (
  <div className="form-group">
    <label className="form-label" htmlFor={name}>{label}<span className="required-field">{required ? '*' : ''}</span>:</label>
    <select className={`form-select ${isEditable ? 'dropdown-arrow' : 'readonly-dropdown'}`} name={name} id={name} required={required} value={value} onChange={onChange} disabled={!isEditable}>
      <option value="">Select {label}</option>
      {options.map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ))}
    </select>
  </div>
);

const ProfileUpdate = () => {
  const [formData, setFormData] = useState(resetFormData());
  const [isNewProfile, setIsNewProfile] = useState(true);
  const [isEditable, setIsEditable] = useState(false); // State to toggle between read-only and editable
  const [notification, setNotification] = useState('');
  const [notificationType, setNotificationType] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const demographicRef = useRef(null);
  const medicalHistoryRef = useRef(null);
  const lifestyleRef = useRef(null);
  const clinicalDataRef = useRef(null);
  const additionalInfoRef = useRef(null);

  // Extract subscriber_id from the state passed via navigate in the login component
  const subscriberId = location.state?.subscriber_id || localStorage.getItem('subscriber_id');

  useEffect(() => {
    if (subscriberId) {
      localStorage.setItem('subscriber_id', subscriberId);
      getProfile(subscriberId)
        .then(response => {
          setFormData(filterProfileData(response.data));
          setIsNewProfile(false);
          setIsEditable(false); // Initially in read-only mode
          navigate('/profile'); // Redirect to /profile for existing users
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            setFormData(resetFormData()); // Use the resetFormData function to reset the form
            setIsNewProfile(true);
            setIsEditable(true); // Allow editing since it's a new profile
            navigate('/profile-update'); // Redirect to /profile-update for new users
          } else {
            console.error('An error occurred while fetching the profile.', error);
          }
        });
    }
  }, [subscriberId, navigate]);

  useEffect(() => {
    if (!isEditable) {
        const formSections = [demographicRef, medicalHistoryRef, lifestyleRef, clinicalDataRef, additionalInfoRef];
        formSections.forEach(sectionRef => {
            const formElement = sectionRef.current;
            if (formElement) {
                formElement.classList.add('readonly-style');
            }
        });
    }
  }, [isEditable]);
  
  useEffect(() => {
    if (!isNewProfile) {
        navigate('/profile');
    }
  }, []);


  // Simplified handleEdit function since the profile data is already fetched
  const handleEdit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setIsEditable(true); // Enable editing
    setNotification(''); // Clear any existing notifications
  };

  // Calculate BMI whenever height or weight changes
  useEffect(() => {
    const calculateBMI = () => {
      if (formData.height && formData.weight) {
        const heightInMeters = formData.height / 100;
        const bmi = (formData.weight / (heightInMeters * heightInMeters)).toFixed(2);
        setFormData(prevFormData => ({ ...prevFormData, bmi }));
      }
    };
    calculateBMI();
  }, [formData.height, formData.weight]);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('profileData'));
    if (savedData) {
      setFormData(savedData);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value || '' // Ensuring value is never undefined
    });
  };

  const handleSave = (sectionRef) => (e) => {
    e.preventDefault();
    const inputs = sectionRef.current.querySelectorAll('input, select');
    const newFormData = { ...formData };

    let isValid = true;
    inputs.forEach(input => {
      if (input.required && !input.value) {
        isValid = false;
      }
      newFormData[input.name] = input.type === 'checkbox' ? input.checked : input.value;
    });

    if (isValid) {
      setFormData(newFormData);
      localStorage.setItem('profileData', JSON.stringify(newFormData));
      showNotification('Profile information saved!');
    } else {
      showNotification('Please fill in all required fields in this section.');
    }
  };

  const clearSection = (sectionRef) => {
    if (sectionRef.current) {
        const inputs = sectionRef.current.querySelectorAll('input, select');
        const updatedFormData = { ...formData };

        inputs.forEach(input => {
            if (input.type === 'checkbox') {
                updatedFormData[input.name] = false;
            } else {
                updatedFormData[input.name] = '';
            }
        });

        setFormData(updatedFormData);
        localStorage.setItem('profileData', JSON.stringify(updatedFormData));
    }
  };
	

  const showNotification = (message, type = 'success') => {
    setNotification(message);
    setNotificationType(type);

    // Clear previous timeout before setting a new one
    const timer = setTimeout(() => {
        setNotification('');
        setNotificationType('');
    }, 5000);

    // Cleanup function to clear timeout if the component unmounts
    return () => clearTimeout(timer);
};

const handleSubmit = (e) => {
  e.preventDefault();

  if (!isEditable) {
      return; // Do not proceed if the form is not editable
  }

  const formDataToSend = new FormData();

  // Loop through formData to append each key-value pair to formDataToSend
  for (const [key, value] of Object.entries(formData)) {
      if (key === 'diagnosticReports' && value instanceof File) {
          formDataToSend.append(key, value); // Append the actual file object
      } else {
          formDataToSend.append(key, value); // Append other form data fields
      }
  }

  formDataToSend.append('subscriber_id', subscriberId);

  const apiCall = isNewProfile ? createProfile : (data) => updateProfile(subscriberId, data);

  apiCall(formDataToSend)
      .then(response => {
          console.log('API response:', response);
          setNotification(`Profile ${isNewProfile ? 'created' : 'updated'} successfully!`, 'success');
          setIsEditable(false); // Make form read-only after updating

          // Apply read-only styles to all sections
          const formSections = [demographicRef, medicalHistoryRef, lifestyleRef, clinicalDataRef, additionalInfoRef];
          formSections.forEach(sectionRef => {
              const formElement = sectionRef.current;
              if (formElement) {
                  formElement.classList.add('readonly-style');
              }
          });

          // Redirect to /profile after submission
          navigate('/profile');
      })
      .catch(error => {
          console.error(`Failed to ${isNewProfile ? 'create' : 'update'} profile:`, error);
          setNotification(`Failed to ${isNewProfile ? 'create' : 'update'} profile`, 'error');
      });
  };

  useEffect(() => {
    if (notification) {
        const timer = setTimeout(() => {
            setNotification('');
            setNotificationType('');
        }, 5000);

        return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }
  }, [notification, notificationType]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];  // Get the first file selected
    if (file) {
        setFormData(prevFormData => ({
            ...prevFormData,
            diagnosticReports: file
        }));
    }
  };

  // Use country-data to get all country names and sort them alphabetically
  const countryOptions = countries.all.map(country => country.name).sort();

  return (
    <div className="profile-update-container">
      <div className="profile-update-header">
        <h2 className="profile-update-title">Please update your profile information</h2>
        <p className="profile-update-subtitle">
          Enhance Your Clinical Trial Matches with Detailed Demographic Data
        </p>
      </div>
      <div className='please-update-div'>
        <div className="profile-update-description">
          Please provide your detailed demographic, medical, lifestyle, and clinical information to help us generate more precise and personalized clinical trial reports for you. Your updated profile will enable us to match you with trials that best fit your health status and preferences. Your data will be securely stored and used solely for research and matching purposes.
        </div>
        <div className='profile-update-image-div'>
          <img className='profile-update-image1' src='/profile-update/book.png' alt='book' />
        </div>
      </div>
      {notification && (
        <div className={`notification ${notificationType}`}>
          {notification}
        </div>
      )}
      <form className="profile-update-form" onSubmit={handleSubmit}>
        {/* Demographic Information Section */}
        <div className="form-section" ref={demographicRef}>
				<h3 className="section-title">Demographic Information</h3>
          <div className="form-group">
            <label className="form-label" htmlFor="fullName">Full Name<span className="required-field">*</span>:</label>
            <input className="form-input" type="text" name="fullName" id="fullName" required value={formData.fullName || ''} onChange={handleChange} readOnly={!isEditable} />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="age">Age<span className="required-field">*</span>:</label>
            <input className="form-input small-input" type="number" name="age" id="age" required value={formData.age || ''} onChange={handleChange} readOnly={!isEditable} />
            <span className="unit-label">Years</span>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="gender">Gender<span className="required-field">*</span>:</label>
            <select className="form-select dropdown-arrow small-input" name="gender" id="gender" required value={formData.gender || ''} onChange={handleChange} disabled={!isEditable}>
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="country">Country<span className="required-field">*</span>:</label>
            <select className="form-select dropdown-arrow small-input" name="country" id="country" required value={formData.country || ''} onChange={handleChange} disabled={!isEditable}>
              <option value="">Select Country</option>
              {countryOptions.map((country, index) => (
                <option key={index} value={country}>{country}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="address">Address<span className="required-field">*</span>:</label>
            <input className="form-input" type="text" name="address" id="address" required value={formData.address || ''} onChange={handleChange} readOnly={!isEditable} />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="pincode">Pincode<span className="required-field">*</span>:</label>
            <input className="form-input small-input" type="text" name="pincode" id="pincode" required value={formData.pincode || ''} onChange={handleChange} readOnly={!isEditable} />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="aadhaar">Aadhaar ID<span className="required-field">*</span>:</label>
            <input className="form-input" type="text" name="aadhaar" id="aadhaar" required value={formData.aadhaar || ''} onChange={handleChange} maxLength="12" readOnly={!isEditable} />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="abha">ABHA ID:</label>
            <input className="form-input" type="text" name="abha" id="abha" value={formData.abha || ''} onChange={handleChange} readOnly={!isEditable} />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email">Email<span className="required-field">*</span>:</label>
            <input className="form-input" type="email" name="email" id="email" required value={formData.email || ''} onChange={handleChange} readOnly={!isEditable} />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="phone">Phone<span className="required-field">*</span>:</label>
            <input className="form-input" type="tel" name="phone" id="phone" required value={formData.phone || ''} onChange={handleChange} readOnly={!isEditable} />
          </div>
          <div className="form-buttons">
            <button 
                type="button" 
                className={`cancel-button demographic-cancel-button ${!isEditable ? 'disabled-button' : ''}`} 
                onClick={() => isEditable && clearSection(demographicRef)}
                disabled={!isEditable}
            >
                Cancel
            </button>
            <button 
                type="button" 
                className={`save-button demographic-save-button ${!isEditable ? 'disabled-button' : ''}`} 
                onClick={handleSave(demographicRef)}
                disabled={!isEditable}
            >
                Save
            </button>
          </div>
        </div>

        {/* Medical History Section */}
        <div className="form-section" ref={medicalHistoryRef}>
          <h3 className="section-title">Medical History</h3>
          <Dropdown label="Medical Conditions" name="medicalConditions" options={medicalConditions} required value={formData.medicalConditions || ''} onChange={handleChange} isEditable={isEditable} />
          <div className="form-group">
            <label className="form-label" htmlFor="medications">Medications<span className="required-field">*</span>:</label>
            <input className="form-input" type="text" name="medications" id="medications" required value={formData.medications || ''} onChange={handleChange} readOnly={!isEditable} />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="allergies">Allergies<span className="required-field">*</span>:</label>
            <input className="form-input" type="text" name="allergies" id="allergies" required value={formData.allergies || ''} onChange={handleChange} readOnly={!isEditable} />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="surgeries">Previous Surgeries/Procedures<span className="required-field">*</span>:</label>
            <input className="form-input" type="text" name="surgeries" id="surgeries" required value={formData.surgeries || ''} onChange={handleChange} readOnly={!isEditable} />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="familyHistory">Family Medical History<span className="required-field">*</span>:</label>
            <input className="form-input" type="text" name="familyHistory" id="familyHistory" required value={formData.familyHistory || ''} onChange={handleChange} readOnly={!isEditable} />
          </div>
          <div className="form-buttons">
            <button 
                type="button" 
                className={`cancel-button medicalhistory-cancel-button ${!isEditable ? 'disabled-button' : ''}`} 
                onClick={() => isEditable && clearSection(medicalHistoryRef)}
                disabled={!isEditable}
            >
                Cancel
            </button>
            <button 
                type="button" 
                className={`save-button medicalhistory-save-button ${!isEditable ? 'disabled-button' : ''}`} 
                onClick={handleSave(medicalHistoryRef)}
                disabled={!isEditable}
            >
                Save
            </button>
          </div>

        </div>
 
        {/* Lifestyle Information Section */}
        <div className="form-section" ref={lifestyleRef}>
          <h3 className="section-title">Lifestyle Information</h3>
          <Dropdown label="Dietary Habits" name="dietaryHabits" options={dietaryHabits} required value={formData.dietaryHabits || ''} onChange={handleChange} isEditable={isEditable} />
          <Dropdown label="Exercise Frequency" name="exerciseFrequency" options={exerciseFrequency} required value={formData.exerciseFrequency || ''} onChange={handleChange} isEditable={isEditable} />
          <Dropdown label="Smoking Status" name="smokingStatus" options={smokingStatus} required value={formData.smokingStatus || ''} onChange={handleChange} isEditable={isEditable} />
          <Dropdown label="Alcohol Consumption" name="alcoholConsumption" options={alcoholConsumption} required value={formData.alcoholConsumption || ''} onChange={handleChange} isEditable={isEditable} />
          <div className="form-group">
            <label className="form-label" htmlFor="substanceUse">Substance Use<span className="required-field">*</span>:</label>
            <input className="form-input" type="text" name="substanceUse" id="substanceUse" required value={formData.substanceUse || ''} onChange={handleChange} placeholder="E.g., Recreational Drugs" readOnly={!isEditable} />
          </div>
          <div className="form-buttons">
            <button 
                type="button" 
                className={`cancel-button lifestyle-cancel-button ${!isEditable ? 'disabled-button' : ''}`} 
                onClick={() => isEditable && clearSection(lifestyleRef)}
                disabled={!isEditable}
            >
                Cancel
            </button>
            <button 
                type="button" 
                className={`save-button lifestyle-save-button ${!isEditable ? 'disabled-button' : ''}`} 
                onClick={handleSave(lifestyleRef)}
                disabled={!isEditable}
            >
                Save
            </button>
          </div>
        </div>

        {/* Clinical Data Section */}
        <div className="form-section" ref={clinicalDataRef}>
          <h3 className="section-title">Clinical Data</h3>
          <div className="form-group">
            <label className="form-label" htmlFor="recentLabResults">Recent Lab Results<span className="required-field">*</span>:</label>
            <input className="form-input" type="text" name="recentLabResults" id="recentLabResults" required value={formData.recentLabResults || ''} onChange={handleChange} placeholder="E.g., Blood tests, Cholesterol levels" readOnly={!isEditable} />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="diagnosticReports">Diagnostic Reports<span className="required-field">*</span>:</label>
            <input className="form-input" type="file" name="diagnosticReports" id="diagnosticReports" onChange={handleFileChange} disabled={!isEditable} />
          </div>
          <div className="form-group small-input-group">
            <label className="form-label" htmlFor="height">Height (cm)<span className="required-field">*</span>:</label>
            <input className="form-input small-input" type="number" name="height" id="height" required value={formData.height || ''} onChange={handleChange} readOnly={!isEditable} />
            <span className="unit-label">CM</span>
          </div>
          <div className="form-group small-input-group">
            <label className="form-label" htmlFor="weight">Weight (kg)<span className="required-field">*</span>:</label>
            <input className="form-input small-input" type="number" name="weight" id="weight" required value={formData.weight || ''} onChange={handleChange} readOnly={!isEditable} />
            <span className="unit-label">KG</span>
          </div>
          <div className="form-group small-input-group">
            <label className="form-label" htmlFor="bmi">BMI (kg/m²)<span className="required-field">*</span>:</label>
            <input className="form-input small-input" type="number" name="bmi" id="bmi" required value={formData.bmi || ''} readOnly />
            <span className="unit-label">kg/m²</span>
          </div>
          <div className="form-buttons">
            <button 
                type="button" 
                className={`cancel-button clinicaldata-cancel-button ${!isEditable ? 'disabled-button' : ''}`} 
                onClick={() => isEditable && clearSection(clinicalDataRef)}
                disabled={!isEditable}
            >
                Cancel
            </button>
            <button 
                type="button" 
                className={`save-button clinicaldata-save-button ${!isEditable ? 'disabled-button' : ''}`} 
                onClick={handleSave(clinicalDataRef)}
                disabled={!isEditable}
            >
                Save
            </button>
          </div>
        </div>

        {/* Additional Information Section */}
        <div className="form-section" ref={additionalInfoRef}>
          <h3 className="section-title">Additional Information</h3>
          <Dropdown label="Participation Availability" name="participationAvailability" options={participationAvailability} required value={formData.participationAvailability || ''} onChange={handleChange} isEditable={isEditable} />
          <div className="form-group checkbox-group">
            <label className="form-label checkbox-label">
              <input className="form-checkbox" type="checkbox" name="agree" id="agree" required checked={formData.agree} onChange={handleChange} disabled={!isEditable} />
              I agree to use my data for matching and research purposes
            </label>
          </div>
          <div className="form-buttons">
            <button 
                type="button" 
                className={`cancel-button additionalinfo-cancel-button ${!isEditable ? 'disabled-button' : ''}`} 
                onClick={() => isEditable && clearSection(additionalInfoRef)}
                disabled={!isEditable}
            >
                Cancel
            </button>
            <button 
                type="button" 
                className={`save-button additionalinfo-save-button ${!isEditable ? 'disabled-button' : ''}`} 
                onClick={handleSave(additionalInfoRef)}
                disabled={!isEditable}
            >
                Save
            </button>
          </div>
        </div>
				<div className="form-buttons">
          {isEditable ? (
              <button type="submit" className="submit-button">Submit</button>
          ) : (
              <button type="button" className="edit-button" onClick={handleEdit}>Edit</button>
          )}
        </div>
      </form>
    </div>
  );
}

export default ProfileUpdate;
